import axios from "axios";
import { VUE_APP_BASE_URL } from "@/services/envrionnement";
import { useNotificationStore } from "@/store/notificationsStore";

const apiClient = axios.create({
  baseURL: VUE_APP_BASE_URL + "/api",
  withCredentials: true,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export const setAuthHeader = (token) => {
  apiClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

let cancelToken;

export default {
  register(userData) {
    return apiClient.post("/register", userData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  login(credentials) {
    return apiClient.post("/login", credentials);
  },
  getMyUserInfo() {
    const token = localStorage.getItem("userToken");
    if (token) {
      setAuthHeader(token);
    }
    return apiClient.get("/user");
  },
  getUserInfoById(userId) {
    const token = localStorage.getItem("userToken");
    if (token) {
      setAuthHeader(token);
    }
    return apiClient.get(`/user/${userId}`);
  },
  updateUser(userId, userData) {
    const token = localStorage.getItem("userToken");
    if (token) {
      setAuthHeader(token);
    }
    return apiClient.put(`/user/${userId}`, userData);
  },
  uploadProfilePicture(formData) {
    const token = localStorage.getItem("userToken");
    if (token) {
      setAuthHeader(token);
    }
    return apiClient.post(`/user/upload-profile-picture`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  isAuthenticated() {
    const token = localStorage.getItem("userToken");
    if (token) {
      setAuthHeader(token);
    }
    return apiClient.get("/is-authenticated");
  },
  addAnnonce(formData) {
    const token = localStorage.getItem("userToken");
    if (token) {
      setAuthHeader(token);
    }
    return apiClient.post("/annonces", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  getlistAnnonces(expand = "") {
    const token = localStorage.getItem("userToken");
    if (token) {
      setAuthHeader(token);
    }
    let url = "/annonces";
    if (expand) {
      url += `?expand=${expand}`;
    }
    return apiClient.get(url);
  },
  getPublicAnnonces(expand = "") {
    let url = "/annonces/public";
    if (expand) {
      url += `?expand=${expand}`;
    }
    return apiClient.get(url);
  },
  demandeAnnonce(plateform) {
    const token = localStorage.getItem("userToken");
    if (token) {
      setAuthHeader(token);
    }
    let url = "/admin/annonces/demandes?plateform=" + plateform;
    return apiClient.get(url);
  },
  acceptDemandeAnnonce(id, payload) {
    const token = localStorage.getItem("userToken");
    if (token) {
      setAuthHeader(token);
    }
    return apiClient.put(`/annonces/${id}/accept`, payload);
  },
  showAnnonce(id) {
    const token = localStorage.getItem("userToken");
    if (token) {
      setAuthHeader(token);
    }
    return apiClient.get(`/annonces/${id}`);
  },
  deleteAnnonce(id) {
    const token = localStorage.getItem("userToken");
    if (token) {
      setAuthHeader(token);
    }
    return apiClient.delete(`/annonces/${id}`);
  },
  addResponseAnnonce(id, response) {
    const token = localStorage.getItem("userToken");
    if (token) {
      setAuthHeader(token);
    }
    return apiClient.post(`/annonces/${id}/responses`, response);
  },
  getResponsesAnnonce(plateform) {
    const token = localStorage.getItem("userToken");
    if (token) {
      setAuthHeader(token);
    }
    return apiClient.get(`/admin/reponses-annonces?plateform=${plateform}`);
  },
  getUserRequests(plateform) {
    const token = localStorage.getItem("userToken");
    if (token) {
      setAuthHeader(token);
    }
    return apiClient.get(`/user/requests?plateform=${plateform}`);
  },
  refuseUserRequest(id) {
    const payload = {
      request_id: id,
    };
    const token = localStorage.getItem("userToken");
    if (token) {
      setAuthHeader(token);
    }
    return apiClient.delete(`/user/requests`, { data: payload });
  },
  acceptUserRequest(id) {
    const payload = {
      request_id: id,
    };
    const token = localStorage.getItem("userToken");
    if (token) {
      setAuthHeader(token);
    }
    return apiClient.put(`/user/requests`, payload);
  },
  verifyEmail(id, token) {
    return apiClient.get(`/verify-email/${id}/${token}`);
  },
  TransmissionAdminReponseAnnonceSend(id, payload) {
    const token = localStorage.getItem("userToken");
    if (token) {
      setAuthHeader(token);
    }
    return apiClient.post(`/admin/reponses-annonces/${id}/send`, payload);
  },
  TransmissionAdminReponseAnnonceRefuse(id, payload) {
    const token = localStorage.getItem("userToken");
    if (token) {
      setAuthHeader(token);
    }
    return apiClient.post(`/admin/reponses-annonces/${id}/refuse`, payload);
  },
  getMyAnnonces() {
    const token = localStorage.getItem("userToken");
    if (token) {
      setAuthHeader(token);
    }
    return apiClient.get(`/user/annonces`);
  },
  getUserReponsesAnnonces(plateforme) {
    const token = localStorage.getItem("userToken");
    if (token) {
      setAuthHeader(token);
    }
    return apiClient.get(`/user/reponses-annonces?plateforme=${plateforme}`);
  },
  refuseReponseAnnonce(id) {
    const token = localStorage.getItem("userToken");
    if (token) {
      setAuthHeader(token);
    }
    return apiClient.get(`/user/reponses-annonces/${id}/refuse`);
  },
  acceptReponseAnnonce(id) {
    const token = localStorage.getItem("userToken");
    if (token) {
      setAuthHeader(token);
    }
    return apiClient.get(`/user/reponses-annonces/${id}/accept`);
  },
  archiveAnnonce(id, payload) {
    const token = localStorage.getItem("userToken");
    if (token) {
      setAuthHeader(token);
    }
    return apiClient.post(`/admin/archive-annonces/${id}`, payload);
  },
  getMyArchive(page = 1) {
    const token = localStorage.getItem("userToken");
    if (token) {
      setAuthHeader(token);
    }
    return apiClient.get(`/user/archive?page=${page}`);
  },
  getAdminStats(section) {
    const token = localStorage.getItem("userToken");
    if (token) {
      setAuthHeader(token);
    }
    return apiClient.get(`/admin/stats?plateform=${section}`);
  },
  getAdminStatsCountAnnonces(plateform) {
    const token = localStorage.getItem("userToken");
    if (token) {
      setAuthHeader(token);
    }
    return apiClient.get(`/admin/stats/count-annonces?plateform=${plateform}`);
  },

  getAdminArchives(
    page = 1,
    search = null,
    dateStart = null,
    dateEnd = null,
    typeVehicule = null,
    plateform = 'lbac'
  ) {
    const token = localStorage.getItem("userToken");
    if (token) {
      setAuthHeader(token);
    }
    let queryString = "";
    if (page || search || dateStart || dateEnd || typeVehicule) {
      queryString += "?";
      queryString += page ? `page=${page}` : "";
      queryString += search ? `&search=${search}` : "";
      queryString += dateStart ? `&dateStart=${dateStart}` : "";
      queryString += dateEnd ? `&dateEnd=${dateEnd}` : "";
      queryString += typeVehicule
        ? `&typeVehicule=${encodeURIComponent(typeVehicule)}`
        : "";
    }
    queryString += plateform ? `&plateform=${plateform}` : "";
    return apiClient.get(`/admin/archives${queryString}`);
  },
  getAdminOnlinesAnnonces(page = 1, plateform = 'lbac') {
    const token = localStorage.getItem("userToken");
    if (token) {
      setAuthHeader(token);
    }
    return apiClient.get(`/admin/onlinesAnnonces?page=${page}&plateform=${plateform}`);
  },
  editNumeroCommandeAdmin(id, payload) {
    const token = localStorage.getItem("userToken");
    if (token) {
      setAuthHeader(token);
    }
    return apiClient.put(`/admin/archives/${id}/edit-commande`, payload);
  },
  getUserNotifications(id) {
    const token = localStorage.getItem("userToken");
    if (token) {
      setAuthHeader(token);
    }
    return apiClient.get(`/user/${id}/notifications`);
  },
  manageUserNotifications(id, payload) {
    const token = localStorage.getItem("userToken");
    if (token) {
      setAuthHeader(token);
    }
    return apiClient.post(`/user/${id}/manage-notifications`, payload);
  },
  addFichierCoffreFort(formData, id) {
    const token = localStorage.getItem("userToken");
    if (token) {
      setAuthHeader(token);
    }
    return apiClient.post(`user/${id}/coffre-fort`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  getUserFiles(id) {
    const token = localStorage.getItem("userToken");
    if (token) {
      setAuthHeader(token);
    }
    return apiClient.get(`/user/${id}/coffre-fort`);
  },
  deleteFileCoffreFort(id) {
    const token = localStorage.getItem("userToken");
    if (token) {
      setAuthHeader(token);
    }
    return apiClient.delete(`/user/coffre-fort/${id}`);
  },
  getNotifications(id) {
    const token = localStorage.getItem("userToken");
    if (token) {
      setAuthHeader(token);
    }
    return apiClient.get(`/notifications/${id}`);
  },
  getAnnonceLogs(id) {
    const token = localStorage.getItem("userToken");
    if (token) {
      setAuthHeader(token);
    }
    return apiClient.get(`/admin/annonces/${id}/logs`);
  },
  getUsers(page = null, search = null, role = null, user_type = null) {
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = axios.CancelToken.source();
    const token = localStorage.getItem("userToken");
    if (token) {
      setAuthHeader(token);
    }

    let queryString = "";
    if (page || search || role) {
      queryString += "?";
      queryString += page ? `page=${page}` : "";
      queryString += search ? `&search=${search}` : "";
      queryString += role ? `&role=${role}` : "";
    }
    if (user_type) {
      queryString += `&user_type=${user_type}`;
    }

    return apiClient.get(`/admin/users${queryString}`, {
      cancelToken: cancelToken.token,
    });
  },
  sendNewsletter(payload) {
    const token = localStorage.getItem("userToken");
    if (token) {
      setAuthHeader(token);
    }
    return apiClient.post(`/admin/send-newsletter`, payload);
  },
  sendReminderAnnonce(id_annonce) {
    const token = localStorage.getItem("userToken");
    if (token) {
      setAuthHeader(token);
    }
    return apiClient.post(`/admin/send-reminder-annonce/${id_annonce}`);
  },
  getStatsSuperAdminCountAnnonces() {
    const token = localStorage.getItem("userToken");
    if (token) {
      setAuthHeader(token);
    }
    return apiClient.get(`/admin/stats/count-SuperAdmin-annonces`);
  },
  getStatsArchivesByMonth(month) {
    const token = localStorage.getItem("userToken");
    if (token) {
      setAuthHeader(token);
    }
    return apiClient.get(`/admin/stats/archives-by-month?month=${month}`);
  },
  getlbactfArchivesArchivesCountByMonth(month) {
    const token = localStorage.getItem("userToken");
    if (token) {
      setAuthHeader(token);
    }
    return apiClient.get(`/admin/stats/lbactfArchives-by-month?month=${month}`);
  },
  registerFromSuperAdmin(payload) {
    const token = localStorage.getItem("userToken");
    if (token) {
      setAuthHeader(token);
    }
    return apiClient.post(`/admin/register-from-superadmin`, payload);
  },
  setPassword(id, token, payload) {
    return apiClient.post(`user/${id}/set-password/${token}`, payload);
  },
  sendForgotPassword(payload) {
    return apiClient.post(`/forgot-password`, payload);
  },
  contactUs(payload) {
    return apiClient.post(`/contact-us`, payload);
  },
  getAdminUserActivity(id) {
    const token = localStorage.getItem("userToken");
    if (token) {
      setAuthHeader(token);
    }
    return apiClient.get(`/admin/user/${id}/activity`);
  },
  updateUserRoles(id, payload) {
    const token = localStorage.getItem("userToken");
    if (token) {
      setAuthHeader(token);
    }
    return apiClient.put(`/admin/user/${id}/update-role`, payload);
  },
  adminDeleteUser(id) {
    const token = localStorage.getItem("userToken");
    if (token) {
      setAuthHeader(token);
    }
    return apiClient.delete(`/admin/user/${id}`);
  },
  superAdminDeleteUser(id) {
    const token = localStorage.getItem("userToken");
    if (token) {
      setAuthHeader(token);
    }
    return apiClient.delete(`/admin/user/${id}/superadmin`);
  },
  adminUpdateAnnonce(id, payload) {
    const token = localStorage.getItem("userToken");
    if (token) {
      setAuthHeader(token);
    }
    return apiClient.put(`/admin/annonces/${id}`, payload);
  },
  AdminVerifUser(id, payload) {
    const token = localStorage.getItem("userToken");
    if (token) {
      setAuthHeader(token);
    }
    return apiClient.put(`/admin/user/${id}/verify`, payload);
  },
  adminSendReminderReponseAnnonce(id) {
    const token = localStorage.getItem("userToken");
    if (token) {
      setAuthHeader(token);
    }
    return apiClient.post(`/admin/send-reminder-reponse-annonce/${id}`);
  },
  getCorbeilles(page = 1, search = null) {
    const token = localStorage.getItem("userToken");
    if (token) {
      setAuthHeader(token);
    }
    let queryString = "";
    if (page || search) {
      queryString += "?";
      queryString += page ? `page=${page}` : "";
      queryString += search ? `&search=${search}` : "";
    }
    return apiClient.get(`/admin/corbeilles${queryString}`);
  },
  deleteCorbeille(id) {
    const token = localStorage.getItem("userToken");
    if (token) {
      setAuthHeader(token);
    }
    return apiClient.delete(`/admin/corbeilles/${id}`);
  },
  clearNotifications(user_id) {
    const token = localStorage.getItem("userToken");
    if (token) {
      setAuthHeader(token);
    }
    return apiClient.delete(`/user/${user_id}/clear-notifications`);
  },
  setAnnonceToWaitingForPublication(id, datePublication) {
    const token = localStorage.getItem("userToken");
    if (token) {
      setAuthHeader(token);
    }
    let payload = {
      date_publication: datePublication,
    };
    return apiClient.post(
      `/admin/annonces/${id}/set-waiting-for-publication`,
      payload
    );
  },
  fetchCalendarAnnonce(month, showPastConvoyages, plateform) {
    const token = localStorage.getItem("userToken");
    if (token) {
      setAuthHeader(token);
    }
    let payload = {
      showPastConvoyages: showPastConvoyages,
      month: month,
      plateform: plateform,
    };
    return apiClient.get(`/calendar/annonces`, { params: payload });
  },
  generateReport(id) {
    const token = localStorage.getItem("userToken");
    if (token) {
      setAuthHeader(token);
    }
    return apiClient
      .get(`/invoice/${id}`, { responseType: "blob" })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `report_${id}.pdf`);
        document.body.appendChild(link);
        link.click();
      });
  },
  async getInvoice(id) {
    const token = localStorage.getItem("userToken");
    if (token) {
      setAuthHeader(token);
    }
    try {
      let data = await apiClient.get(`/invoice/${id}`);
      if (data.status === 200) {
        return data;
      }
      throw new Error("Error");
    } catch (error) {
      useNotificationStore().addNotification({
        type: "error",
        message: "Erreur lors de la génération de la facture",
      });
    }
  },
  async generatePDF(invoiceData) {
    const token = localStorage.getItem("userToken");
    if (token) {
      apiClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
    try {
      let data = await apiClient.post(`/invoice/generate-pdf`, invoiceData, {
        responseType: "blob",
      });
      if (data.status === 200) {
        useNotificationStore().addNotification({
          type: "success",
          message: "Bon de commande PDF générée avec succès",
        });
        return data;
      }
      throw new Error("Error");
    } catch (error) {
      useNotificationStore().addNotification({
        type: "error",
        message: "Erreur lors de la génération de la facture PDF",
      });
    }
  },
  showArchive(id) {
    const token = localStorage.getItem("userToken");
    if (token) {
      setAuthHeader(token);
    }
    return apiClient.get(`/admin/archive/${id}`);
  },
  updateArchive(id, payload) {
    const token = localStorage.getItem("userToken");
    if (token) {
      setAuthHeader(token);
    }
    return apiClient.put(`/admin/archive/${id}`, payload);
  },
  deleteArchive(id) {
    const token = localStorage.getItem("userToken");
    if (token) {
      setAuthHeader(token);
    }
    return apiClient.delete(`/admin/archive/${id}`);
  },
  /**
   * Récupère toutes les images de l'album admin.
   * @returns {Promise} Promesse contenant la liste des images.
   */
  getAlbumImages() {
    const token = localStorage.getItem("userToken");
    if (token) {
      setAuthHeader(token);
    }
    return apiClient.get(`/admin/album/images`);
  },
  /**
   * Upload de nouvelles images dans l'album admin.
   * @param {FormData} formData - Les images à uploader.
   * @returns {Promise} Promesse contenant la réponse du serveur.
   */
  uploadAlbumImages(formData) {
    const token = localStorage.getItem("userToken");
    if (token) {
      setAuthHeader(token);
    }
    return apiClient.post(`/admin/album/images`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  /**
   * Supprime une image de l'album admin.
   * @param {number} imageId - L'ID de l'image à supprimer.
   * @returns {Promise} Promesse contenant la réponse du serveur.
   */
  deleteAlbumImage(imageId) {
    const token = localStorage.getItem("userToken");
    if (token) {
      setAuthHeader(token);
    }
    return apiClient.delete(`/admin/album/images/${imageId}`);
  },
};
